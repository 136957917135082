<template>
  <b-overlay :show="isFetchingCmsArticles">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-manage-article')"
        >
          New Article
        </b-button>
      </b-col>
    </b-row>
    <b-table
      :fields="fields"
      :items="cmsArticles"
      responsive
    >
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(coverImageSrc)="data">
        <b-img-lazy
          :src="data.value"
          width="auto"
          height="80px"
        />
      </template>
      <template #cell(detail)="data">
        <div>
          <h6 class="text-primary">
            Title
          </h6>
          <p>{{ data.item.title }}</p>
          <h6 class="text-primary">
            Short description
          </h6>
          <p>{{ data.item.shortDescription || '-' }}</p>
        </div>
      </template>
      <template #cell(content)="data">
        <div>
          <b-button
            size="sm"
            variant="info"
            @click="onPreviewContent(data.value)"
          >
            Preview
          </b-button>
        </div>
      </template>
      <template #cell(id)="data">
        <div>
          <b-button
            size="sm"
            variant="danger"
            @click="onRemoveArticle(data.value)"
          >
            Remove
          </b-button>
        </div>
      </template>
    </b-table>
    <ManageCmsArticle />
    <ContentPreview :content="previewContent" />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    ManageCmsArticle: () => import('./manage-article.vue'),
    ContentPreview: () => import('./content-preview.vue'),
  },
  data() {
    return {
      previewContent: null,
      fields: [
        '#',
        {
          key: 'coverImageSrc',
          label: 'Cover Image',
          thStyle: {},
        },
        {
          key: 'slug',
          label: 'Slug',
        },
        'Detail',
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'author',
          label: 'Author',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['siteId']),
    ...mapState({
      isFetchingCmsArticles: (state) => state.cms.isFetchingCmsArticles,
      cmsArticles: (state) => state.cms.cmsArticles,
    }),
  },
  methods: {
    ...mapActions(['removeCmsArticle']),
    onRemoveArticle(articleId) {
      this.$bvModal.msgBoxConfirm('Are you sure?').then((value) => {
        if (value) {
          this.removeCmsArticle({ siteId: this.siteId, articleId })
        }
      })
    },
    onPreviewContent(content) {
      this.previewContent = content
      this.$bvModal.show('preview-cms-article')
    },
  },
}
</script>
